<template>
  <v-dialog
    v-if="help != true"
    :width="this.size != null ? size : '900'"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <v-card id="newPopup">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        >{{ $t("inventorys.brands") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col cols="10" md="3" class="shorter">
            <v-text-field
              outlined
              dense
              v-model="filters.search"
              prepend-inner-icon="mdi-magnify"
              class="ml-auto filters search"
              :label="$t('search')"
              hide-details
              single-line
              @input="searchFilter"
            />
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="2" md="3" style="padding-top: 6px" align="right">
            <brands-new @save="fetchBrands"></brands-new>
          </v-col>
        </v-row>

        <v-data-table
          :footer-props="{
            'items-per-page-text': $vuetify.breakpoint.smAndDown
              ? ''
              : $t('items_per_page'),
          }"
          :headers="headers"
          :items="items"
          :search.sync="filters.search"
          :options.sync="options"
          :server-items-length="totalBrands"
          :loading="loading"
          :sort-by.sync="sortBy"
        >
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" large
                  >mdi-dots-horizontal</v-icon
                >
              </template>
              <v-list>
                <brands-edit
                  :id="item.id"
                  @updated="
                    fetchBrands();
                    $emit('refresh');
                  "
                ></brands-edit>
                <v-list-item class="braList" @click="del(item)">
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -3px !important"
                      >$delete</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span>{{ $t("delete", { name: "" }) }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

import { debounce } from "lodash";

export default {
  name: "BrandList",
  watch: {
    options: {
      handler() {
        this.fetchBrands();
      },
      deep: true,
    },
    dialog: {
      handler() {
        if (dialog) this.fetchBrands();
      },
      deep: true,
    },
  },
  props: ["title"],
  components: {
    BrandsForm: () => import("@/components/brand/Form"),
    BrandsNew: () => import("@/views/brand/BrandNew"),
    BrandsEdit: () => import("@/views/brand/BrandEdit"),
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("brand.name"),
          align: "center",
          sortable: false,
          value: "brand_name",
        },

        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      brand: {},
      items: [],
      dialog: false,
      filters: {
        search: "",
        dates: "",
      },
      sortBy: "Nombre",
      rowsPerPageItems: [10, 25, 50, 100, 200],
      options: {},
      loading: true,
      totalBrands: 0,
    };
  },
  mounted() {
    this.fetchBrands();
  },
  methods: {
    ...mapActions("brand", ["getBrands", "deleteBrand"]),

    saveBrand() {
      this.addBrand({ brand: this.brand }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("brand_create_success"), "", "");
          this.dialog = false;
        }
      });
    },

    del(brand) {
      this.$confirm(this.$t("delete_ask")).then(() => {
        this.deleteBrand(brand.id).then((x) => {
          this.fetchBrands();
          this.$alert(this.$t("delete_ok"));
        });
      });
    },
    fetchBrands() {
      this.loading = true;
      this.getBrands({
        pagination: this.options,
        filters: this.filters,
      }).then((brand) => {
        //console.log(brand);
        this.items = brand.data;
        this.totalBrands = brand.total;
        this.loading = false;
      });
    },
    searchFilter: debounce(function(val) {
      this.fetchBrands();
    }, 1000),
  },
};
</script>
<style lang="sass">
.filters .svg-icon, .filters .v-icon
  fill: var(--v-primary-base)
  color: var(--v-primary-base)
  height: 20px

.filters .v-label
  color: white
.v-application .col-6
  //padding-right: 12px !important

.braList
  height: 30px
  .v-list-item__icon
    margin-right: 5px !important
#newPopup

.braList .v-icon, .braList .svg-icon
  margin-top: -3px
  color: var(--v-gris1-lighten2)

  @media (min-width: 800px)
    .shorter
      max-width: 170px
</style>
